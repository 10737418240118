<template lang="pug">
div.d-flex.flex-column
    HeaderGrid(:name="name" :uid="uid" :dateRanger="dateRanger" :tvaModeButton="tvaModeButton" :addButton="addButton" :searchButton="searchButton" :tagguerButton="tagguerButton" :selectedRows="selectedRows" @onFilterTextBoxChanged="onFilterTextBoxChanged" @selectParamerterAction="selectParamerterAction" @clickAction="clickAction" :searchPlaceholder="searchPlaceholder" :archiveMode="archiveMode" :archive="archive" @refreshRow="refreshRow" :indexSearch="indexSearch")
    .d-flex.w-100.h-100(style="overflow: hidden;")
      .text-center.flex-center.w-100(v-if='isLoadingMissionsList || !headerLoaded || isLoadingAnalyticsSettingsList')
        .loading-bg-inner
          .loader
            .outer
            .middle
            .inner
        .mt-5
            br
            br
            br
            | Chargement de la liste des tâches..
      sync-tree-grid#overviewtreegrid(ref="overviewtreegrid" v-else :editSettings='editSettings' :parentIdMapping="parentIdMapping" :idMapping="idMapping" :hasChildMapping="hasChildMapping" :treeColumnIndex="treeColumnIndex" :allowGrouping="allowGrouping" :gridData="dataFiltered" :name="name" :headerData="headerData" @rowClicked="onRowClicked" @setSelectedRows="setSelectedRows" :tagguerButton="tagguerButton" @this.$refs.overviewtreegrid="this.$refs.overviewtreegrid" @deleteButtonClicked="deleteButtonClicked" @actionBegin="actionBegin" @archiveButtonClicked="archiveButtonClicked")
</template>

<script>
import { mapGetters, mapActions } from "vuex";

import { formatCurrency } from "@/types/api-orisis/library/FormatOperations.ts";
import { setColumnsByObject } from "@/types/api-orisis/library/ObjectOperations.ts";

var dayjs = require("dayjs");
var quarterOfYear = require("dayjs/plugin/quarterOfYear");
var utc = require("dayjs/plugin/utc");
var timezone = require("dayjs/plugin/timezone");
var advanced = require("dayjs/plugin/advancedFormat");
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.tz.setDefault("Europe/Paris");
dayjs.extend(advanced);
dayjs.extend(quarterOfYear);

import SyncTreeGrid from "@/components/global/grid/TreeGrid.vue";
import HeaderGrid from "@/components/global/grid/HeaderGrid.vue";

export default {
  components: {
    SyncTreeGrid,
    HeaderGrid,
  },
  data() {
    return {
      indexSearch: 0,
      name: "mission",
      uid: "mission",
      archive: false,
      headerData: [],
      headerLoaded: false,
      tabActive: "all",
      selectedRows: [],

      dateRanger: false,
      tvaModeButton: false,
      addButton: true,
      searchButton: true,
      tagguerButton: false,
      archiveMode: false,
      searchPlaceholder: "Rechercher une mission",
      editSettings: {
        allowEditing: true,
        allowAdding: true,
        allowDeleting: true,
        mode: "Dialog",
        titleAdd: "Nouvelle mission",
        titleEdit: "Modifier la mission",
        titleField: "label",
      },
      allowGrouping: false,
      parentIdMapping: "parentId",
      idMapping: "id",
      hasChildMapping: "parentId",
      treeColumnIndex: 1,
    };
  },
  computed: {
    ...mapGetters([
      "missionsList",
      "missionsListTemplate",
      "isLoadingMissionsList",
      "workspaceSelected",
      "unitsList",
      "analyticsSettingsList",
      "isLoadingAnalyticsSettingsList",
    ]),
    dataFiltered() {
      return this.missionsList.map((elem) => {
        return {
          ...elem,
          parentId: elem.parentId == 0 ? null : elem.parentId,
          hasChild: this.missionsListTemplate.filter(
            (child) => child.parentId == elem.id && child.id !== elem.id
          )
            ? true
            : false,
        };
      });
    },
    analyticsSettingsListFiltered() {
      return this.analyticsSettingsList.map((elem) => {
        return {
          ...elem,
          hasChild: elem.children.length > 0 ? true : false,
        };
      });
    },
  },
  async created() {
    if (!this.unitsList || this.unitsList.length == 0) {
      await this.getUnits({});
    }
    if (!this.analyticsSettingsList || this.analyticsSettingsList.length == 0) {
      await this.getAnalyticsSettings({});
    }
    this.setHeaderData();
  },
  async activated() {
    await this.getMissions({}).then(async (res) => {
      this.setHeaderData();
      if (res.length) {
        await this.setColumnsByObject(res[0], null, this);
        this.indexSearch++;
      }
      this.headerLoaded = true;
    });
  },
  methods: {
    ...mapActions([
      "getMissions",
      "createMission",
      "updateMission",
      "archiveMissions",
      "deleteMissions",
      "getUnits",
      "getAnalyticsSettings",
    ]),
    formatCurrency,
    setColumnsByObject,
    setHeaderData() {
      this.headerData = [
        {
          type: "checkbox",
          headerText: null,
          width: 30,
          maxWidth: 30,
          visible: false,
        },
        {
          type: "string",
          field: "label",
          headerText: "Libellé",
          width: "auto",
          minWidth: 200,
          allowFiltering: true,
          allowSorting: true,
          allowEditing: true,
          showInColumnChooser: true,
          visible: true,
          validationRules: { required: true, minLength: 2 },
          clipMode: "EllipsisWithTooltip",
        },
        {
          type: "number",
          field: "analyticsSettingId",
          filterTemplate: "analyticsSettingFilterTemplate",
          headerText: "Code analytique lié",
          width: 200,
          minWidth: 200,
          allowFiltering: true,
          allowSorting: true,
          allowEditing: true,
          showInColumnChooser: true,
          visible: true,
          valueAccessor: "dropdown",
          edit: {
            source: this.analyticsSettingsListFiltered,
            fields: {
              text: "label",
              value: "id",
              parentValue: "parentAnalyticsSetting",
              // hasChildren: "hasChild",
              // expanded: "hasChild",
              dataSource: this.analyticsSettingsListFiltered,
            },
            type: "dropdowntree",
          },
          validationRules: { required: true },
        },
        {
          type: "number",
          format: "formatNumber",
          field: "estimatedQuantity",
          format: "formatNumber",
          headerText: "Qté par défaut",
          width: 200,
          minWidth: 200,
          allowFiltering: true,
          allowSorting: true,
          allowEditing: true,
          showInColumnChooser: true,
          visible: true,
          editType: "numericedit",
          textAlign: "Center",
          edit: { params: { decimals: 2 } },
        },
        {
          type: "number",
          field: "unitId",
          headerText: "Unité par défaut",
          filterTemplate: "unitFilterTemplate",
          template: "unitTemplate",
          minWidth: 160,
          allowFiltering: true,
          allowSorting: true,
          allowEditing: true,
          showInColumnChooser: true,
          visible: true,
          dataSource: this.unitsList,
          edit: {
            source: this.unitsList,
            fields: {
              text: "label",
              value: "id",
            },
            name: "unitId",
            type: "dropdown",
          },
        },
        {
          type: "number",
          field: "estimatedUnitPrice",
          format: "formatNumber",
          headerText: "Prix U. par défaut",
          width: 200,
          minWidth: 200,
          allowFiltering: true,
          allowSorting: true,
          allowEditing: true,
          showInColumnChooser: true,
          visible: true,
          editType: "numericedit",
          textAlign: "Center",
          edit: { params: { decimals: 2 } },
        },
        {
          type: "number",
          field: "parentId",
          headerText: "Mission parente",
          width: 1,
          maxWidth: 1,
          allowFiltering: true,
          allowSorting: true,
          allowEditing: true,
          showInColumnChooser: true,
          visible: true,
          valueAccessor: "dropdown",
          edit: {
            source: this.dataFiltered,
            fields: {
              text: "label",
              value: "id",
              parentValue: "parentId",
              hasChildren: "hasChild",
              expanded: "hasChild",
              dataSource: this.dataFiltered,
            },
            type: "dropdowntree",
          },
        },
      ];
    },
    async actionBegin(args) {
      if (args.requestType === "save" && args.action === "add") {
        await this.createMission({
          mission: {
            ...args.data,
            id: 0,
            parentId: !args.data.parentId ? 0 : args.data.parentId.toString(),
            analyticsSettingId: args.data.analyticsSettingId.toString(),
            estimatedQuantity: args.data.estimatedQuantity
              ? args.data.estimatedQuantity
              : 0,
            estimatedUnitPrice: args.data.estimatedUnitPrice
              ? args.data.estimatedUnitPrice
              : 0,
            advancedActualQuantity: args.data.advancedActualQuantity
              ? args.data.advancedActualQuantity
              : 0,
            advancedValuatedQuantity: args.data.advancedValuatedQuantity
              ? args.data.advancedValuatedQuantity
              : 0,
          },
        }).then(async (res) => {
          this.setHeaderData();
          this.$refs.overviewtreegrid.$refs.overviewtreegrid.refresh();
        });
      } else if (args.requestType === "save" && args.action === "edit") {
        await this.updateMission({
          mission: {
            ...args.data,
            parentId: !args.data.parentId ? 0 : args.data.parentId.toString(),
            analyticsSettingId: args.data.analyticsSettingId.toString(),
            estimatedQuantity: args.data.estimatedQuantity
              ? args.data.estimatedQuantity
              : 0,
            estimatedUnitPrice: args.data.estimatedUnitPrice
              ? args.data.estimatedUnitPrice
              : 0,
            advancedActualQuantity: args.data.advancedActualQuantity
              ? args.data.advancedActualQuantity
              : 0,
            advancedValuatedQuantity: args.data.advancedValuatedQuantity
              ? args.data.advancedValuatedQuantity
              : 0,
          },
        }).then(async (res) => {
          this.setHeaderData();
          this.$refs.overviewtreegrid.$refs.overviewtreegrid.refresh();
        });
      } else if (args.requestType === "add") {
        if (
          this.$refs.overviewtreegrid.$refs.overviewtreegrid.getSelectedRecords() &&
          this.$refs.overviewtreegrid.$refs.overviewtreegrid.getSelectedRecords()
            .length > 0
        ) {
          let oldData =
            this.$refs.overviewtreegrid.$refs.overviewtreegrid.getSelectedRecords()[0];
          args.rowData.parentId = [oldData.parentId.toString()];
          args.rowData.analyticsSettingId = [];
        }
      }
    },
    clickAction() {
      this.$refs.overviewtreegrid.$refs.overviewtreegrid.addRecord();
    },
    onRowClicked() {},
    async archiveButtonClicked(args) {
      await this.archiveMissions({ missionIds: [args.rowData.id] });
      this.$refs.overviewtreegrid.$refs.overviewtreegrid.closeEdit();
      this.$refs.overviewtreegrid.$refs.overviewtreegrid.refresh();
    },
    async deleteButtonClicked(args) {
      await this.deleteMissions({ missionIds: [args.rowData.id] });
      this.$refs.overviewtreegrid.$refs.overviewtreegrid.closeEdit();
      this.$refs.overviewtreegrid.$refs.overviewtreegrid.refresh();
    },
    onFilterTextBoxChanged(search) {
      this.$refs.overviewtreegrid.onFilterTextBoxChanged(search);
    },
    selectParamerterAction(args) {
      if (["csv", "xls", "print"].includes(args.item.type)) {
        this.$refs.overviewtreegrid.exportData(args.item.type);
      } else if (args.item.type == "archive") {
        this.archive = true;
      } else if (args.item.type == "current") {
        this.archive = false;
      } else {
        this.$refs.overviewtreegrid.$refs.overviewtreegrid.openColumnChooser();
      }
    },
    setSelectedRows(val) {
      this.selectedRows = val;
    },
    refreshRow(val) {
      this.$refs.overviewtreegrid.$refs.overviewtreegrid.setRowData(
        val.id,
        val
      );
    },
  },
};
</script>
